import { Box, Image as ChakraImage, Heading, Link, SystemStyleObject, Table, Text } from '@chakra-ui/react';
import parse, { Element, HTMLReactParserOptions, domToReact } from 'html-react-parser';
import Image from 'next/image';
import NextLink from 'next/link';

import { extractPxFromString } from '@/utils/extract-px-from-string';
import { DOMElements } from '@/utils/models/parse-html-to-react';

import { Mark, MarkColorScheme } from './Mark';
import { LargeQuote, LargeQuoteAuthor, QuoteColorScheme, SimpleQuote } from './Quote';

const {
  P,
  A,
  SPAN,
  STRONG,
  IMG,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  NEWSLETTER,
  QUOTATION_HEADING_LIME,
  QUOTATION_HEADING_VIOLET,
  QUOTATION_AUTHOR,
  BLOCKQUOTE,
  FIGCAPTION,
  MARK,
  TABLE,
} = DOMElements;

const quotationHeadingColorSchemeMap = {
  [QUOTATION_HEADING_LIME]: QuoteColorScheme.Lime,
  [QUOTATION_HEADING_VIOLET]: QuoteColorScheme.Violet,
};

export enum AvailablePenColors {
  Lime = 'lime',
  Violet = 'violet',
}

const getQuotationHeadingColorScheme = (domElement: keyof typeof quotationHeadingColorSchemeMap) =>
  quotationHeadingColorSchemeMap[domElement];

const markColorSchemes = Object.values(MarkColorScheme);

const getMarkColorScheme = (classNames?: string) => {
  const classNamesArray = (classNames?.split(' ') as MarkColorScheme[]) || [];

  return classNamesArray.find((className) => markColorSchemes.includes(className));
};

export const getColorFromClassName = (className: string): string => className.split('-')[1];

export const parseOptions: HTMLReactParserOptions = {
  replace: (domElement) => {
    if (domElement instanceof Element) {
      const { name, children } = domElement;

      switch (name) {
        case P: {
          if (children.length === 1 && 'data' in children[0] && !children[0].data.trim()) {
            return <></>;
          }
          return <Text as="p">{domToReact(children, parseOptions)}</Text>;
        }
        case A:
          return (
            <Link
              as={NextLink}
              href={domElement.attribs.href}
              className={domElement.attribs.href.includes('@') ? 'email' : ''}
            >
              {domToReact(children, parseOptions)}
            </Link>
          );
        case SPAN:
          return <Text as="span">{domToReact(children, parseOptions)}</Text>;
        case STRONG:
          return <Text as="strong">{domToReact(children, parseOptions)}</Text>;
        case IMG:
          const width = extractPxFromString(domElement.attribs.width);
          const isWidthUnknown = width === 0;
          return (
            <Box
              as="span"
              sx={{
                img: {
                  margin: '0 auto',
                },
              }}
            >
              {isWidthUnknown ? (
                <ChakraImage src={domElement.attribs.src} alt={domElement.attribs.alt} />
              ) : (
                <Image
                  src={domElement.attribs.src}
                  alt={domElement.attribs.alt}
                  sizes={domElement.attribs.sizes}
                  width={width}
                  height={width}
                />
              )}
            </Box>
          );
        case H1:
          return <Heading as="h1">{domToReact(children, parseOptions)}</Heading>;
        case H2:
          return <Heading as="h2">{domToReact(children, parseOptions)}</Heading>;
        case H3:
          return <Heading as="h3">{domToReact(children, parseOptions)}</Heading>;
        case H4:
          return <Heading as="h4">{domToReact(children, parseOptions)}</Heading>;
        case H5:
          return (
            <Heading as="h5" className={H5}>
              {domToReact(children, parseOptions)}
            </Heading>
          );
        case H6:
          return <Heading as="h6">{domToReact(children, parseOptions)}</Heading>;
        case NEWSLETTER:
          return null; // Legacy, newsletter block is not part of RichText anymore
        case QUOTATION_HEADING_LIME:
        case QUOTATION_HEADING_VIOLET: {
          const nextSibling = domElement.next;

          const colorScheme = getQuotationHeadingColorScheme(name);

          let author;
          if (nextSibling instanceof Element && nextSibling.name === QUOTATION_AUTHOR) {
            author = <LargeQuoteAuthor>{domToReact(nextSibling.children, parseOptions)}</LargeQuoteAuthor>;
          }

          return (
            <LargeQuote author={author} colorScheme={colorScheme}>
              {domToReact(children, parseOptions)}
            </LargeQuote>
          );
        }
        case QUOTATION_AUTHOR: {
          const prevSibling = domElement.prev;
          if (
            prevSibling instanceof Element &&
            ([QUOTATION_HEADING_LIME, QUOTATION_HEADING_VIOLET] as string[]).includes(prevSibling.name)
          ) {
            return <></>;
          }
        }
        case BLOCKQUOTE: {
          return <SimpleQuote colorScheme={QuoteColorScheme.Violet}>{domToReact(children, parseOptions)}</SimpleQuote>;
        }
        case TABLE: {
          return (
            <Table mt="3" mb="8">
              {domToReact(children, parseOptions)}
            </Table>
          );
        }
        case FIGCAPTION: {
          return (
            <Text variant="text-xs/lineHeight-4/font-normal/0.75rem" as="figcaption" color="purple.300" pt="2">
              {domToReact(children, parseOptions)}
            </Text>
          );
        }
        case MARK: {
          const classNames = domElement.attribs.class;
          const colorScheme = getMarkColorScheme(classNames);

          return <Mark colorScheme={colorScheme}>{domToReact(children, parseOptions)}</Mark>;
        }
      }
    }
  },
};

interface ParsedHTMLTextProps {
  content: string;
  innerStyles?: SystemStyleObject;
}

export const ParsedHTMLText = ({ content, innerStyles }: ParsedHTMLTextProps) => {
  return <Box sx={innerStyles}>{typeof content === 'string' ? parse(content, parseOptions) : content}</Box>;
};
