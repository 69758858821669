import { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ChakraProps, Flex, useInterval } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import styled from '@emotion/styled';

type SliderProps = {
  snapDirection?: 'start' | 'center';
  gapPX?: number;
  autoScrollIntervalMs?: number;
  autoScrollStopOnHover?: boolean;
  autoScrollStopOnInteraction?: boolean;
  children: ReactNode[];
} & ChakraProps;

type RefProps = {
  onPrev: () => void;
  onNext: () => void;
};

const DEFAULT_GAP = 16;

export const Slider = forwardRef<RefProps, SliderProps>(
  (
    {
      children,
      gapPX = DEFAULT_GAP,
      autoScrollIntervalMs,
      autoScrollStopOnHover = true,
      autoScrollStopOnInteraction = true,
      snapDirection = 'start',
      ...chakraProps
    },
    ref,
  ) => {
    const sliderContainer = useRef<HTMLDivElement>(null);
    const sliderDimensions = useSize(sliderContainer);
    const [isScrolling, setIsScrolling] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [userInteracted, setUserInteracted] = useState(false);
    const [itemWidth, setItemWidth] = useState(100);

    const itemsLength = children.length || 0;

    const autoScrollInterval = () => {
      if (userInteracted && autoScrollStopOnInteraction) {
        return null;
      }
      if (isHovered && autoScrollStopOnHover) {
        return null;
      }
      return autoScrollIntervalMs || null;
    };

    useEffect(() => {
      if (sliderContainer.current) {
        const items = sliderContainer.current.querySelectorAll(':scope > *');
        const itemsWidth = Array.from(items).reduce((sum, item) => sum + item.clientWidth, 0);
        setItemWidth(itemsWidth / itemsLength);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliderContainer.current, sliderDimensions?.width]);

    useEffect(() => {
      let buttonBlockTimeout = isScrolling ? setTimeout(() => setIsScrolling(false), 400) : null;
      return () => {
        if (buttonBlockTimeout) {
          clearTimeout(buttonBlockTimeout);
        }
      };
    }, [isScrolling]);

    useInterval(() => {
      if (autoScrollIntervalMs) {
        switchSlide('right');
      }
    }, autoScrollInterval());

    useImperativeHandle(
      ref,
      () => {
        return {
          onPrev: () => switchSlide('left', true),
          onNext: () => switchSlide('right', true),
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isScrolling, itemWidth, itemsLength],
    );

    const switchSlide = (direction: 'left' | 'right', userAction?: boolean) => {
      if (userAction) {
        setUserInteracted(true);
      }

      if (sliderContainer.current && !isScrolling) {
        if (!sliderContainer.current) {
          throw 'Could not get slider container reference correctly';
        }
        setIsScrolling(true);
        const container = sliderContainer.current;
        const { scrollLeft, scrollWidth, offsetWidth } = container;

        if (autoScrollIntervalMs && !userAction && scrollLeft >= scrollWidth - offsetWidth) {
          container.scroll({ left: 0, behavior: 'smooth' });
          return;
        }
        container.scroll({
          left: direction === 'left' ? scrollLeft - itemWidth : scrollLeft + itemWidth,
          behavior: 'smooth',
        });
      }
    };

    return (
      <CustomSlider
        ref={sliderContainer}
        snapdirection={snapDirection}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        custommargin={gapPX}
        mr={{ xs: -5, sm: -8, md: -20, '3xl': -80 }}
        pr={{ base: 4, xs: 5, sm: 8, md: 20, '3xl': 80 }}
        pl={{ base: 4, xs: 0 }}
        {...chakraProps}
      >
        {children}
      </CustomSlider>
    );
  },
);

const CustomSlider = styled(Flex)<{
  custommargin?: number;
  snapdirection: 'start' | 'center';
}>`
  display: flex;
  height: 100%;
  overflow: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: ${({ snapdirection }) => snapdirection};
    margin-right: ${({ custommargin }) => `${custommargin || DEFAULT_GAP}px`};

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

Slider.displayName = 'Slider';
