import { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useInView } from 'framer-motion';
import Image, { StaticImageData } from 'next/image';

type AnimatedTextHighlightProps = PropsWithChildren & {
  image: StaticImageData;
  standalone?: boolean;
  once?: boolean;
  speed?: string;
  scaleX?: number;
  scaleY?: number;
  top?: string;
  style?: CSSProperties;
  textColor?: string;
  delay?: string;
};

const DEFAULT_ANIMATION_SPEED = '1.6s';

export const TextDecorationPng = ({
  speed = DEFAULT_ANIMATION_SPEED,
  delay,
  children,
  once,
  image,
  scaleX,
  scaleY,
  standalone,
  top,
  textColor,
  style,
}: AnimatedTextHighlightProps) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(containerRef);

  useEffect(() => {
    if (isInView) {
      setShouldAnimate(true);
    } else if (!once) {
      setShouldAnimate(false);
    }
  }, [isInView, once]);

  return (
    <Wrapper
      animate={shouldAnimate}
      ref={containerRef}
      speed={speed}
      scaleX={scaleX}
      scaleY={scaleY}
      standalone={!!standalone}
      top={top}
      delay={delay}
      as="span"
    >
      <Box as={Image} src={image} alt="" style={style} className="image-highlight" />
      <Box as="span" style={{ whiteSpace: 'nowrap' }} color={textColor || 'inherit'}>
        {children}
      </Box>
    </Wrapper>
  );
};

type WrapperType = {
  animate?: boolean;
  speed?: string;
  scaleX?: number;
  scaleY?: number;
  standalone: boolean;
  top?: string;
  delay?: string;
};

const Wrapper = styled.span<WrapperType>`
  position: ${({ standalone }) => (standalone ? 'absolute' : 'relative')};
  display: inline-block;
  width: ${({ standalone }) => (standalone ? '100%' : 'min-content')};
  height: ${({ standalone }) => (standalone ? '100%' : 'min-content')};
  z-index: -1;

  img {
    position: absolute;
    top: ${({ top }) => top || 0};
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: left;
    transform: scale(
      ${({ animate, scaleX }) => (animate ? (scaleX || 1) * 100 + '%' : '0')},
      ${({ scaleY }) => scaleY || 1}
    );
    width: 0;
    height: 100%;
    z-index: -1;
    animation: ${({ animate, speed, delay }) =>
      `${animate ? 'penMove' : 'none'} ${speed} linear ${delay || ''} forwards`};
  }

  @keyframes penMove {
    0%,
    25% {
      width: 0;
    }
    60% {
      width: 50%;
    }
    75% {
      width: 85%;
    }
    100% {
      width: 105%;
    }
  }
`;
