export enum DOMElements {
  P = 'p',
  A = 'a',
  STRONG = 'strong',
  SPAN = 'span',
  IMG = 'img',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  NEWSLETTER = 'newsletter',
  QUOTATION_HEADING_LIME = 'quotation-heading-lime',
  QUOTATION_HEADING_VIOLET = 'quotation-heading-violet',
  QUOTATION_AUTHOR = 'quotation-author',
  BLOCKQUOTE = 'blockquote',
  FIGCAPTION = 'figcaption',
  MARK = 'mark',
  TABLE = 'table',
}
