import { HTMLAttributes, PropsWithChildren } from 'react';

import { chakra } from '@chakra-ui/react';
import { StandardLonghandProperties } from 'node_modules/csstype';

const DEFAULT_CLASS_NAME = 'text-highlight';
const DEFAULT_ENLARGEMENT_X = '0px';
const DEFAULT_ENLARGEMENT_Y = '0px';
const DEFAULT_OFFSET_X = '0px';

interface TextHighlightProps extends PropsWithChildren {
  backgroundImage: StandardLonghandProperties['backgroundImage'];
  backgroundColor?: StandardLonghandProperties['backgroundColor'];
  childrenClassName?: HTMLAttributes<'className'>;
  enlargmentX?: string;
  enlargmentY?: string;
  offsetX?: string;
}

export const TextHighlightSvgStatic = (props: TextHighlightProps) => {
  const {
    backgroundImage,
    backgroundColor,
    childrenClassName = DEFAULT_CLASS_NAME,
    enlargmentX = DEFAULT_ENLARGEMENT_X,
    enlargmentY = DEFAULT_ENLARGEMENT_Y,
    offsetX = DEFAULT_OFFSET_X,
    children,
  } = props;

  const isNegative = (value: string): boolean => value.includes('-');
  const makeNonNegative = (value: string): string => value.replace('-', '');

  if (isNegative(enlargmentX || enlargmentY)) {
    throw new Error('`enlargmentX` and `enlargmentY` props can only be non negative.');
  }

  let offsetRight: string = DEFAULT_OFFSET_X;
  let offsetLeft: string = DEFAULT_OFFSET_X;

  if (isNegative(offsetX)) {
    offsetLeft = makeNonNegative(offsetX);
  } else {
    offsetRight = offsetX;
  }

  return (
    <chakra.span
      sx={{
        [`& > * .${childrenClassName}`]: {
          marginTop: `calc(-1 * ${enlargmentY})`,
          marginRight: `calc(-1 * (${enlargmentX} + ${offsetRight}))`,
          marginBottom: `calc(-1 * ${enlargmentY})`,
          marginLeft: `calc(-1 * (${enlargmentX} + ${offsetLeft}))`,
          paddingTop: enlargmentY,
          paddingRight: `calc(${enlargmentX} + ${offsetRight})`,
          paddingBottom: enlargmentY,
          paddingLeft: `calc(${enlargmentX} + ${offsetLeft})`,
          backgroundImage,
          backgroundColor,
          backgroundSize: `calc(100% - ${makeNonNegative(offsetX)})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        },
      }}
    >
      {children}
    </chakra.span>
  );
};
