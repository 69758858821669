import { PropsWithChildren, ReactNode } from 'react';

import { Box, ColorProps, Text, chakra } from '@chakra-ui/react';
import Highlight from 'images/highlights/quote-highlight.svg';

export enum QuoteColorScheme {
  Violet = 'violet',
  Lime = 'lime',
}

export interface LargeQuoteProps {
  children: ReactNode;
  author?: ReactNode;
  colorScheme: QuoteColorScheme;
}

const quoteTextColorMap: Record<QuoteColorScheme, ColorProps['color']> = {
  [QuoteColorScheme.Lime]: 'purple.500',
  [QuoteColorScheme.Violet]: 'white',
};

const quoteBgColorMap: Record<QuoteColorScheme, ColorProps['color']> = {
  [QuoteColorScheme.Lime]: 'lime.500',
  [QuoteColorScheme.Violet]: 'violet.500',
};

const getQuoteTextColor = (colorScheme: QuoteColorScheme) => quoteTextColorMap[colorScheme];

const getQuoteBgColor = (colorScheme: QuoteColorScheme) => quoteBgColorMap[colorScheme];

export const LargeQuote = ({ children, author, colorScheme }: LargeQuoteProps) => {
  return (
    <chakra.figure
      mx={{
        base: 0,
        xl: -32,
      }}
      my={{
        base: 8,
        xs: 10,
        md: 14,
      }}
      position="relative"
      pr={{
        base: 0,
        xs: 6,
        md: 10,
      }}
      pl={{
        base: 10,
        xs: 14,
      }}
      py="5"
      color={getQuoteTextColor(colorScheme)}
    >
      <QuoteBackground colorScheme={colorScheme} />
      <Text
        position="absolute"
        as="span"
        fontSize={{
          base: '90px',
          xs: '130px',
          md: '150px',
        }}
        left={{
          base: '-8px',
          md: '-24px',
        }}
        top={{
          base: '-28px',
          md: '-40px',
        }}
        color="violet.800"
        fontWeight="600"
        lineHeight="60px"
      >
        ,,
      </Text>
      <Text
        as="blockquote"
        variant={{
          base: 'text-md/lineHeight-6/font-semibold',
          xs: 'text-xl/lineHeight-7/font-semibold',
          md: 'text-2xl/lineHeight-8/font-semibold',
        }}
      >
        {children}
      </Text>
      {author}
    </chakra.figure>
  );
};

export interface SimpleQuoteProps {
  children: ReactNode;
  colorScheme: QuoteColorScheme;
}

export const SimpleQuote = ({ children, colorScheme }: SimpleQuoteProps) => {
  return (
    <Text as="blockquote" my="8" position="relative" mx="-8" px="8" py="5" color={getQuoteTextColor(colorScheme)}>
      <QuoteBackground colorScheme={colorScheme} />
      {children}
    </Text>
  );
};

export const LargeQuoteAuthor = ({ children }: PropsWithChildren) => {
  return (
    <Text
      as="figcaption"
      textAlign="end"
      variant={{
        base: 'text-md/lineHeight-6/font-normal',
        xs: 'text-xl/lineHeight-7/font-normal',
        md: 'text-2xl/lineHeight-8/font-normal',
      }}
    >
      - {children}
    </Text>
  );
};

export interface QuoteBackgroundProps {
  colorScheme: QuoteColorScheme;
}

export const QuoteBackground = ({ colorScheme }: QuoteBackgroundProps) => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex={-1}
      color={getQuoteBgColor(colorScheme)}
      sx={{
        '.highlight': {
          w: 'full',
          h: 'full',
        },
      }}
    >
      <Highlight className="highlight" />
    </Box>
  );
};
