import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Box, BoxProps, Text, keyframes } from '@chakra-ui/react';
import { useInView } from 'framer-motion';

type AnimatedTextHighlightProps = PropsWithChildren & {
  line: React.ElementType;
  color?: 'lime' | 'violet';
  variant?: 'line' | 'circle' | 'exclaim';
  once?: boolean;
  speed?: string;
  delay?: string;
  strokeWidth?: number;
  noAnimation?: boolean;
  scaleX?: number;
  scaleY?: number;
} & BoxProps;

const DEFAULT_ANIMATION_TIME = '1s';
const DEFAULT_STROKE_WIDTH = 3;

export const TextDecorationSvg = ({
  variant = 'line',
  speed = DEFAULT_ANIMATION_TIME,
  strokeWidth = DEFAULT_STROKE_WIDTH,
  children,
  once,
  line,
  color,
  delay,
  noAnimation,
  scaleX,
  scaleY,
  ...chakraBoxProps
}: AnimatedTextHighlightProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<any>(null);
  const isInView = useInView(containerRef);

  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [lineLength, setLineLength] = useState(false);
  const [computedScaleX, setComputedScaleX] = useState(scaleX ?? 1);
  const [computedScaleY, setComputedScaleY] = useState(scaleY ?? 1);

  const isCircle = variant === 'circle';
  const isUnderline = variant === 'line';

  useEffect(() => {
    if (lineRef.current) {
      const lineBox = lineRef.current;
      const lengthOfLine = lineBox.querySelector('path')?.getTotalLength() || 100;
      const containerWidth = containerRef.current?.offsetWidth ?? 0;
      setLineLength(lengthOfLine);
      if (isCircle) {
        const width = lineBox.querySelector('path')?.getBoundingClientRect().width;
        const scale = containerWidth && width ? Number((containerWidth / width).toFixed(2)) : 1;
        setComputedScaleX(scale);
      }
      if (isUnderline) {
        const containerHeight = containerRef.current?.offsetHeight ?? 0;
        if ((containerWidth ?? 1) / (containerHeight ?? 1) > 4) {
          setComputedScaleY(0.6);
        }
      }
    }
  }, [isCircle, isUnderline]);

  useEffect(() => {
    if (noAnimation) {
      return;
    }
    if (isInView) {
      setShouldAnimate(true);
    } else if (!once) {
      setShouldAnimate(false);
    }
  }, [isInView, noAnimation, once]);

  return (
    <Box as="span" position="relative" ref={containerRef}>
      <Text as="span" whiteSpace="nowrap">
        <Box
          as="span"
          position="absolute"
          w={isCircle ? '120%' : '100%'}
          h={isCircle ? '120%' : '100%'}
          top={isCircle ? '-10%' : '50%'}
          left={isCircle ? '-10%' : 0}
          zIndex={0}
          {...chakraBoxProps}
          ref={lineRef}
        >
          <Box
            as={line}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            opacity={shouldAnimate || noAnimation ? 1 : 0}
            sx={{
              '& path': {
                stroke: color === 'lime' ? 'lime.500' : 'violet.500',
                strokeWidth,
                strokeDasharray: lineLength,
                strokeDashoffset: lineLength,
                animation: `${shouldAnimate ? lineAnimation : 'none'} ${speed} linear ${
                  delay || ''
                } forwards alternate`,
              },
              transform: `scaleX(${computedScaleX}) scaleY(${computedScaleY})`,
            }}
          />
        </Box>
        {children}
      </Text>
    </Box>
  );
};

const lineAnimation = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;
