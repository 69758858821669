import { ReactNode } from 'react';

import HighlightImageLime from 'images/highlights/client-opinions-highlight.png';
import Underline from 'images/highlights/line-1-straight.svg';
import HighlightImageViolet from 'images/highlights/violet-text-highlight.png';

import { AvailablePenColors, TextDecorationPng, getColorFromClassName } from '@/components/functional';
import { TextDecorationSvg } from '@/components/functional';

export enum MarkColorScheme {
  UnderlineViolet = 'pen-violet',
  UnderlineLime = 'pen-lime',
  HighlightViolet = 'marker-violet',
  HighlightLime = 'marker-lime',
}

enum PenType {
  Pen = 'pen',
  Marker = 'marker',
}

export interface MarkProps {
  children: ReactNode;
  colorScheme?: MarkColorScheme;
}

export const Mark = ({ children, colorScheme }: MarkProps) => {
  if (colorScheme?.includes(PenType.Marker)) {
    const color = getColorFromClassName(colorScheme);
    const Image = color === 'lime' ? HighlightImageLime : HighlightImageViolet;
    return (
      <TextDecorationPng image={Image} textColor={color === 'lime' ? 'initial' : 'white'} scaleX={1.05}>
        {children}
      </TextDecorationPng>
    );
  }

  if (colorScheme?.includes(PenType.Pen)) {
    return (
      <TextDecorationSvg line={Underline} color={getColorFromClassName(colorScheme) as AvailablePenColors}>
        {children}
      </TextDecorationSvg>
    );
  }

  return <>{children}</>;
};
